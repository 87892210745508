import React from 'react';
import Layout from '../components/layout';
import MyNavbar from '../components/navbar/navbar';
import Footer from '../components/footer';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Breadcrumb from "../components/breadcrumb";
import Sidebar from '../components/sidebar';
import Cta from "../components/cta";

import main_img from "../images/plasma/plasma_5.jpg";
import img_1 from '../images/plasma/plasma_1.jpg';
import img_2 from '../images/plasma/plasma_2.jpg';
import img_3 from '../images/plasma/plasma_3.jpg';
import img_4 from '../images/plasma/plasma_4.jpg';
import favicon from '../images/favicon.ico';

function Plasma() {
    return (
        <Layout>
            <Helmet>
                <title>Plasma bearbetning i Södertälje - www.danasplat.se</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Vi har en modern plasmaskärmaskin och utför Plasma bearbetning i vår modern verkstad i Södertälje." />
                <meta name="keywords" content="plasma bearbetning, plasmaskärmaskin" />
                <meta property="og:title" content="Plasma bearbetning - Danas Plåt och Smide AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://danasplat.se/plasma"/>
                <link rel="cannonical" href="https://danasplat.se/plasma"/>
            </Helmet>

            <MyNavbar active_page="" />
            <section>
                <div className="h-60 md:h-96 w-screen bg-plasma bg-center bg-no-repeat bg-cover">
                    <div className="h-60 md:h-96 w-screen  bg-opacity-50 bg-black flex justify-center items-center ">
                        <h1 className="text-4xl sm:text-5xl md:text-7xl font-extrabold text-center text-white">Plasma bearbetning</h1>
                    </div>
                </div>
                <Breadcrumb page="Plasma"/>
            </section>


            <section>
                <div className="container mx-auto px-4 pt-24">
                    <div className="grid lg:grid-cols-2 gap-24 lg:gap-10">
                        <div className="bg-white">
                            <div className="w-full h-full flex flex-col justify-center">
                                <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-left text-green-900">Plasma bearbetning med högsta skärkvalitet</h2>
                                <p className="lg:text-xl text-gray-600 mt-12">Vi på Danas Plåt och Smide AB har en modern verkstad och erbjuder även plasma bearbetning. Plasmaskärning är ett bra alternativ eftersom den ger liten värmepåverkan på materialet, hög skärhastighet, högsta skärkvalitet och precisions.</p>
                                <p className="lg:text-xl text-gray-600 mt-6">Rent praktiskt så är det så att en plasmaskärare skapar en ljusbåge med hjälp av elektricitet och den fokuserade plasmastrålen ger mycket precisa skärresultat. Plasma används för att bearbeta exempelvis stål, rostfritt, aluminium, mässing och m.m. </p>
                                <p className="lg:text-xl text-gray-600 mt-6">Vi har idag en modern plasmaskärmaskin som används för kvalitet, precision och kostnadseffektivitet. Vi kan erbjuda dig rätt kvalitet till rätt pris. Kontakta oss för mer information.</p>
                                <Link to="/kontakt" className="block mt-12">
                                    <span className="bg-red-700 shadow-xl text-white text-md md:text-xl py-4 px-6 rounded hover:bg-red-800">Kontakta oss</span>
                                </Link>
                            </div>
                        </div>

                        <div className="w-full h-full">
                            <div className="w-full h-full bg-image-square bg-no-repeat bg-custome bg-right-bottom">
                                <div className="w-full h-full bg-image-circel bg-no-repeat bg-custome bg-left-top p-4 lg:p-8">
                                    <img alt="Plasma bearbetning" className="w-full rounded-tl-3xl rounded-br-3xl rounded-tr-md rounded-bl-md  shadow-xl object-cover h-full object-center block" src={main_img} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 pt-24 pb-32">
                    <hr/>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 pb-32">
                    <div className="grid grid-cols-12 gap-10">
                        <div className="col-span-full lg:col-span-3">
                            <Sidebar />
                        </div>
                        <div className="col-span-full lg:col-span-9">
                            <div className="w-full grid lg:grid-cols-2 gap-10">
                                <img alt="bearbeta stål med plasma" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_1} />
                                <img alt="plasmaskärmaskin " className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_2} />
                                <img alt="högsta skärkvalitet" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_3} />
                                <img alt="Plasmaskärning" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_4} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Cta />
            <Footer />
        </Layout>
    );
}
export default Plasma;